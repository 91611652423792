import { Form } from 'dataformjs';
import { navigate, PageProps } from 'gatsby';
import React, { FC } from 'react';
import { useMutation } from 'react-apollo';
import { useTranslation } from 'react-i18next';

import GridSplit from '../../../../components/base/GridSplit';
import organizationUserData from '../../../../objects/administrators/datas/create.json';
import { administratorCreate as createMutation } from '../../../../objects/administrators/mutations';
import requiredAuth from '../../../../utils/requireAuth';

const OrganizationUsersCreatePage: FC<PageProps> = ({
  params: { organizationId },
}) => {
  console.info('organizationId', organizationId);
  const formName = 'administrator';
  const [createMutationFunc] = useMutation(createMutation);
  const { t } = useTranslation();

  const handleOnSubmit = async (values: any) => {
    const result = await createMutationFunc({
      variables: { ...values, organizationId },
    });

    if (!result || result.errors) {
      console.error(result.errors);
    } else {
      const administratorId = result.data.organizationUsersCreate.id;
      window.history.replaceState({}, '', `/admin/organizations/`);
      navigate(`/admin/administrators/update/${administratorId}/`);
    }
  };

  return (
    <GridSplit
      header={{
        back: {
          onClick: () => {
            window.history.back();
          },
          text: t(`back`),
        },
        title: `admin.administrators.form.create.title`,
      }}
    >
      <Form
        className="mx-6 mb-6"
        datas={organizationUserData}
        footerProps={{
          className: 'mt-12 flex justify-end',
        }}
        name={formName}
        onSubmit={handleOnSubmit}
        submitProps={{
          label: 'admin.administrators.form.create.submit.label',
        }}
      />
    </GridSplit>
  );
};

export default requiredAuth(OrganizationUsersCreatePage, {
  admin: true,
  layout: true,
});
